import type { CorePageProps } from "@/types/page";
import type { ReactElement } from "react";
import { useMemo } from "react";
import { useRouter } from "next/router";
import type { GetStaticProps } from "next";
import { getStaticPropsForPage } from "@/lib/contentful/get-static-props-for-page";
import { PageSeo } from "@/components/contentful/seo-metadata/page-seo";
import { BaseLayout } from "@/layouts/base-layout";
import { usePageHomepageQuery } from "@/components/contentful/page-homepage/__generated/page-homepage.contentful.generated";
import { prefetchHomepagePage } from "@/lib/contentful/pages/prefetch-homepage-page";
import { PageHomepageGraphql } from "@/components/contentful/page-homepage/page-homepage.graphql";

type HomepagePageProps = CorePageProps;

const HomepagePage = ({ pageId, siteSettingsId }: HomepagePageProps) => {
    const { locale, isPreview: preview } = useRouter();

    const { data } = usePageHomepageQuery(
        {
            id: pageId,
            locale: locale!,
            preview,
        },
        {
            ...(!preview && { staleTime: Infinity }),
        }
    );

    const seo = useMemo(() => {
        return {
            title: String(data?.page?.title),
            description: data?.page?.seoMetaDescription,
            openGraphImage: data?.page?.heroAsset,
            noindex: data?.page?.robotIndex,
            nofollow: data?.page?.robotFollow,
        };
    }, [
        data?.page?.heroAsset,
        data?.page?.robotFollow,
        data?.page?.robotIndex,
        data?.page?.seoMetaDescription,
        data?.page?.title,
    ]);

    return (
        <>
            <PageSeo {...seo} siteSettingsId={siteSettingsId} />
            <PageHomepageGraphql id={pageId} locale={locale!} preview={preview} />
        </>
    );
};

HomepagePage.getLayout = (page: ReactElement) => <BaseLayout>{page}</BaseLayout>;

export const getStaticProps: GetStaticProps<HomepagePageProps> = async ({ locale, draftMode }) => {
    const pageProps = await getStaticPropsForPage({
        locale: locale!,
        preview: Boolean(draftMode),
        queryHook: usePageHomepageQuery,
        prefetcher: prefetchHomepagePage,
        slug: "/",
        microcopySetKeys: [],
    });

    if (!pageProps) {
        return {
            notFound: true,
        };
    }

    return {
        props: {
            ...pageProps,
        },
        revalidate: 60,
    };
};

export default HomepagePage;
